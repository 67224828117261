.container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.exception_company_list_container {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 6px;
    background: var(--gray-50-background-divider, #F3F4F8);
}

.exception_company_list_title {
    flex: 1 0 0;
    color: var(--gray-900---heading, #1A1818);
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
}

.exception_company_list_count {
    color: var(--gray-900---heading, #1A1818);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
}

.exception_company_list_search_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.exception_company_list_search_title {
    color: var(--gray-900---heading, #1A1818);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}


.exception_search_box {
    width: 100%;
    position: relative;
}

.search_exception_company_list_container {
    padding: 6px 0;
    background: rgb(255, 255, 255);
    border: 1px solid var(--gray-200---divider, #E2E5EA);
    border-radius: 10px;
    z-index: 1300;
    visibility: visible;
}

.search_item {
    display: flex;
    align-content: center;
    height: 38px;
    padding: 14px 12px;
    cursor: pointer;
    box-sizing: border-box;

    color: var(--gray-900---heading, #1A1818);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    z-index: 10;
}

.search_item:hover {
    background: var(--gray-10, #F9FAFD);
}

.on_item {
    background: var(--gray-10, #F9FAFD);
}