.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
}

.list_box > div:first-child {
    display: flex;
    padding: 8px;
    gap: 8px;
    flex-wrap: wrap;
    align-content: flex-start;

    border-radius: 6px;
    border: 1px solid var(--gray-200---divider, #E2E5EA);
    background: var(--white, #FFF);
    box-sizing: border-box;
}

.chip {
    display: flex;
    height: 24px;
    padding: 4px;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background: #F4F5F8;
    box-sizing: border-box;
}

.chip span {
    color: #1A1818;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
}

.container button {
    padding: 0;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
}

.chip button {
    height: 16px;
}

.reset_button {
    color: var(--gray-600, #6D717B);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
}