.title_box { display: flex; flex-direction: column; align-items: center; max-height: 84vh; height: 100%; padding-top: 10%; padding-bottom: 100px; text-align: center; background: #F4F7FA } 

.title_box .btn_wrap { margin-top: 50px; } 

@media (max-width: 1023px){
 .title_box { padding-top: 11.35vh; } 
 }

@media (max-width: 767px){
 .title_box { padding-top: 19vh; } 
 }

@media screen and (max-width: 299px){
 .title_box { padding-top: 12.5vh; } 
 }

.title_box h2 { color: #1A1818; text-align: center; font-size: 54px; font-style: normal; font-weight: 700 !important; line-height: normal; word-break: keep-all; } 

.title_box h2 span { display: inline-block; position: relative; z-index: 9; font-size: 54px; font-style: normal; font-weight: 700 !important; line-height: normal; } 
@media (max-width: 1023px){
 .title_box h2 { font-size: 34px !important; } 
 .title_box h2 span { font-size: 34px !important;; } 
 }


.title_box h2 span::before { content: ''; display: inline-block; position: absolute; left: 0; bottom: 6px; width: 100%; height: 20px; border-radius: 100px; background-color: #D2B5FF; z-index: -1; } 

@media (max-width: 1023px){
 .title_box h2 span::before { height: 10px; } 
 }

.title_box p { margin-top: 8px; color: #555; text-align: center; font-size: 18px; font-style: normal; font-weight: 700; line-height: 18px; word-break: keep-all; /* 100% */ } 

@media (max-width: 1023px){
 .title_box p { font-size: 16px; } 
 }

.title_box a { -webkit-animation: fade1 0.8s forwards; animation: fade1 0.8s forwards; z-index: 999; } 

@media (max-width: 1023px){
 /* .title_box a { position: fixed; width: calc(93% / 2)!important; bottom: 16px; z-index: 999; } 

 .title_box a.is_login { width: calc(100% - 40px); } 

 .title_box .btn_wrap a:first-child { left: 20px; } 
 .title_box .btn_wrap a:last-child { right: 20px; }  */
 }

 @media (max-width: 767px){
 /* .title_box a { width: calc(90% / 2) !important; }  */
 }

@media (max-width:574px){
 /* .title_box a { width: calc(85% / 2) !important; }  */
 }


 .main_container h2 {
  color: #1A1818;
  font-size: 44px;
  font-weight: 700;
  line-height: normal;
 }

 .title_content {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
 }

.logo_container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 90px 0 150px 0;
  overflow: hidden;
}


.logo_container_top {
  display: flex;
  gap: 24px;
  animation: marquee 80s linear infinite;
}

.logo_container_bottom {
  display: flex;
  gap: 24px;
  animation: marquee2 200s linear infinite;
}

@media (max-width: 768px) {
  .logo_container {
    margin: 60px 0;
  }
  
}




@keyframes marquee {
  0%   { transform: translateX(-300px); }
  100% { transform: translateX(calc(-345px * 11)); }
}

@keyframes marquee2 {
  0%   { transform: translateX(calc(-345px * 11)); }
  100% { transform: translateX(-240px); }
}

.count_container {
  display: flex;
  margin: 0 auto;
  padding: 100px 20px;
  width: 100%;
  max-width: 1230px;
  justify-content: space-between;
}


.count_item_box {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.count {
  color: var(--expert-primary-purple-500, #866DEC);
  font-size: 46px;
  font-weight: 800;
}

@media (max-width: 768px) {
  .count_container {
    padding: 60px 20px;
    flex-direction: column;
    justify-content: center;
  }

  .count {
    font-size: 30px;
  }

  .main_container h2 {
    font-size: 22px;
    font-weight: 700;
  }
}

.count span {
  font-weight: 500;
}

.count_item_title {
  margin-top: 10px;
  color: #1A1818;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px; /* 136.364% */
}

.main_item_container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 150px 20px;
}


.main_item_container_2_wrapper {
  background: #f5f5f5;
  width: 100%;
}

.main_item_container_2 {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 150px 20px;
}

@media (max-width: 1023px) {
  .main_item_container {
    padding: 60px 20px;
  }
  .main_item_container_2 {
    padding: 60px 20px;
  }
}


.main_tit {
  color: #1A1818;
  font-size: 2rem;
  font-weight: 700;
  line-height: normal;
}

.main_content {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.swiper_wrapper {
  display: flex;
}

.main_item1_background {
  max-width: 1000px;
  height: 680px;
  margin: 100px auto 0;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}

:where(.main_item1_slide, .main_item2_slide) {
  border-radius: 10px;
  box-shadow: 30px 30px 50px 0px rgba(0, 0, 0, 0.10);
  opacity: 1 !important;
}

.main_item1_slide:nth-child(1){
  position: absolute;
  top: 12em;
  left: -20%;
  width: 398px !important;
  height: 243px;
  background: no-repeat center/contain url("assets/images/main/main_item_sub_1.png");
}


.main_item1_slide:nth-child(2) {
  position: absolute;
  top: 10em;
  left: 38%;
  width: 365px !important;
  height: 243px;
  background: no-repeat center/contain url("assets/images/main/main_item_sub_2.png");
}

.main_item1_slide:nth-child(3) {
  position: absolute;
  top: 35em;
  left: -5%;
  width: 303px !important;
  height: 229px;
  background: no-repeat center/contain url("assets/images/main/main_item_sub_3.png");
}

.main_item2_swiper {
  overflow: visible;
  position: relative;
}

:where(.main_item1_swiper, .main_item2_swiper) > div:nth-child(2){
  display: none;
}

.main_item2_background {
  max-width: 769px;
  height: 680px;
  margin: 100px auto 0;
  border: 1px solid #E2E5EA;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}

.main_item2_slide:nth-child(1){
  position: absolute;
  top: 3em;
  left: 34%;
  width: 365px !important;
  height: 243px;
  background: no-repeat center/contain url("assets/images/main/main_item2_sub_1.png");
}


.main_item2_slide:nth-child(2) {
  position: absolute;
  top: 28em;
  left: 43%;
  width: 316px !important;
  height: 257px;
  background: no-repeat center/contain url("assets/images/main/main_item2_sub_2.png");
}

.main_item2_slide:nth-child(3) {
  position: absolute;
  top: 38em;
  left: -18%;
  width: 303px !important;
  height: 229px;
  background: no-repeat center/contain url("assets/images/main/main_item2_sub_3.png");
}

.main_item2_slide:nth-child(4) {
  position: absolute;
  top: 14em;
  left: -24%;
  width: 316px !important;
  height: 203px;
  background: no-repeat center/contain url("assets/images/main/main_item2_sub_4.png");
}

@media (max-width: 1023px) {

  .main_item1_background {
    max-width: 80%;
  }

  .main_item2_background {
    max-width: 60%
  }

  .main_item1_slide:nth-child(1){
    top: 6em;
    left: -25%;
  }

  .main_item1_slide:nth-child(2) {
    top: 3em;
    left: 2em;
  }

  .main_item1_slide:nth-child(3) {
    top: 25em;
    left: -10%;
  }


  :where(.main_item2_slide, .main_item1_slide) {
    transform: scale(0.75) !important;
  }

  .main_item2_slide:nth-child(1){
    top: 3em;
    left: 20%;
  }

  .main_item2_slide:nth-child(2) {
    top: 28em;
    left: 32%;
  }

  .main_item2_slide:nth-child(3) {
    top: 38em;
    left: -10%;
  }

  .main_item2_slide:nth-child(4) {
    top: 14em;
    left: -20%;
  }
}

@media (max-width: 767px) {
  .main_item1_swiper {
    margin-top: 20px;
    margin-bottom: 0px;
    height: 400px;
    background: no-repeat center/contain url("assets/images/main/main_item_1.jpg");
  }

  .main_item2_swiper {
    margin-top: 20px;
    margin-bottom: 40px;
    height: 512px;
    background: no-repeat center/contain url("assets/images/main/main_contract_img_mob.png");
  }

  :where(.main_item1_swiper, .main_item2_swiper) > div:nth-child(1) {
    align-items: flex-end;
    transform: translate3d(202.5px, 0px, 0px);
  }

  :where(.main_item1_swiper, .main_item2_swiper) > div:nth-child(2){
    display: block;
  }


  .main_item2_background {
    display: none;
  }

  :where(.main_item1_slide, .main_item2_slide) {
    position: relative !important;
    box-shadow: none;
  }

  .main_item1_slide:nth-child(1){
    width: 280px !important;
    height: 243px;
    top: 0;
    left: 0;
  }

  .main_item1_slide:nth-child(2) {
    width: 280px !important;
    height: 243px;
    top: 0;
    left: 0;
  }

  .main_item1_slide:nth-child(3) {
    width: 280px !important;
    height: 243px;
    top: 0;
    left: 0;
  }

  .main_item2_slide:nth-child(1){
    width: 280px !important;
    height: 243px;
    top: 0em;
    left: 0em;
  }


  .main_item2_slide:nth-child(2) {
    width: 280px !important;
    height: 243px;
    top: 0em;
    left: 0em;
  }

  .main_item2_slide:nth-child(3) {
    width: 280px !important;
    height: 243px;
    top: 0em;
    left: 0em;
  }

  .main_item2_slide:nth-child(4) {
    width: 280px !important;
    height: 243px;
    top: 0em;
    left: 0em;
  }
}



.service_swiper > div:first-child {
  flex-wrap: wrap;
  gap: 40px;
}

.service_swiper > div:last-child {
  display: none;
}

.service_item_slide {
  width: calc(100% / 3 - 40px) !important;
}
.service_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 340px;
  padding: 32px;
  background: var(--expert-primary-purple-10, #F9F8FE);
  border-radius: 8px;
}

@media (max-width: 767px) {

  .btn_wrap {
    flex-direction: column;
  }

  .service_item_slide {
    width: 100% !important;
  }

  .service_swiper > div:first-child {
    flex-wrap: nowrap;
    gap: 0;
  }
  
  .service_swiper > div:last-child {
    display: block;
  }
  

}

.service_item span {
  color: #1A1818;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  word-break: keep-all;
}

.service_item p {
  color: #555;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  word-break: keep-all;
}

@media (max-width: 1023px) {
  .service_item span {
    font-size: 18px;
  }

  .service_item p {
    font-size: 14px;
  }
}



.service_item_container {
  flex-wrap: wrap;
  justify-content: center !important;
  column-gap: 40px !important;
}

.why_item_container {
  justify-content: center !important;
  column-gap: 40px !important;
}

@media (max-width: 1300px) {
  .why_item_container {
    flex-wrap: wrap;
  }

  .why_itm_img {
    width: 100%;
    object-fit: contain;
  }
}


.footer_title {
  text-align: center;
  word-break: keep-all;
  color: #fff !important;
}

.footer_top_container {
  padding: 100px 20px;
  background: var(--expert-primary-gradient-500, linear-gradient(132deg, #3478D1 0%, #A67BED 95.93%));
}

.footer_top_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
}

.footer_btn_1 {
  border: 1px solid white !important;
}

.footer_top_container .footer_top_inner .footer_btn_2 {
  background: transparent !important;
  border: 1px solid white !important;
  color: #fff !important;
}

.footer_btn_2:is(:hover,:active,:visited) {
  color: #fff !important;
}

.footer_bottom_container {
  padding: 0 20px;
  background: #343434;
}

.footer_inner {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 43px 0;
}

.footer_logo {
  max-width: 440px;
  width: 100%;
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
}

.footer_info {
  display: flex;
  gap: 25px !important;
  align-items: flex-start !important;
}

.footer_info * {
  color: #DDD;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.footer_info_item {
  flex-wrap: wrap;
}

@media (max-width: 1023px) {
  .footer_top {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
  }

  .footer_info_item {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  
  .footer_copy_item_container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .last_footer {
    align-items: flex-start !important;
    flex-direction: column !important;
  }

  .divider {
    display: none;
  }
}

.border_btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 130px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #DDD;

  color: #DDD;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.last_footer a{
  color: #999;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.footer_copy {
  max-width: 440px;
  width: 100%;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1023px) {
  .footer_copy{
      width: 100%;
      margin-top: 10px;
  }
}
