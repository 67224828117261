.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;

  color: #1A1818;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px; /* 100% */
}

.method_list {
  display: flex;
  gap: 24px;
  align-self: stretch;
}

.method_list > li {
  display: flex;
  width: 400px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 12px;
  background: #F4F5F8;
}

.step {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: var(--expert-primary-purple-900, #1B162F);

  color: var(--white, #FFF);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.step2 {
  background: var(--expert-primary-purple-800, #362C5E);
}

.step3 {
  background: var(--expert-primary-purple-700, #50418E);
}

.method_step_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.method_step_title {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.method_step_desc {
  color: var(--gray-700---text, #52545C);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  min-height: 120px;
}

.copy_button {
  display: flex;
  width: 20px;
  padding: 2px;
  align-items: center;
  gap: 10px;

  border-radius: 6px;
  background: var(--gray-100, #EDEEF2);
  outline: none;
  border: none;
  cursor: pointer;
}

.cms_form_container {
  margin-top: 10px;
}

.cms_form_container li {
  list-style: inside;
}

.manger_container h3 {
  color: #1A1818;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px; /* 100% */
}

.wage_box {
  display: flex;
  width: 100%;
  padding: 30px 20px;
  margin: 24px 0;
  align-items: flex-start;
  gap: 20px;

  border-radius: 6px;
  border: 1px solid var(--gray-200---divider, #E2E5EA);
}

.wage_box_item {
  /*height: 45px;*/
  flex: 1;
}

.box_title {
  color: var(--gray-700---text, #52545C);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.box_content {
  color: var(--gray-900---heading, #1A1818);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
}

.employee_count_title {
  color: var(--gray-900---heading, #1A1818);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.count_box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 100%;
  padding: 10px;

  border-radius: 6px;
  background: var(--gray-50-background-divider, #F3F4F8);

  color: var(--gray-900---heading, #1A1818);
  font-size: 14px;
  font-weight: 600;
  line-height: 14px; /* 100% */
}

.employee_count_content {
  color: var(--gray-700---text, #52545C);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  word-break: keep-all;
}


.item {
  width: 100%;
}

.item > h3 {
  height: 50px;
}

.item_price {
  color: var(--gray-700---text, #52545C);

  /* body/large-600 */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
}

.add_subscription_item {
  padding: 30px 20px;
  width: 100%;

  border-radius: 6px;
  border: 1px solid var(--gray-200---divider, #E2E5EA);
}

:where(.add_subscription_item, .subscribed_item) > div {
  width: 100%;
}

:where(.add_subscription_item, .subscribed_item) > div:not(:first-child) {
  position: relative;
}

:where(.add_subscription_item, .subscribed_item) > div:not(:first-child)::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: var(--gray-200---divider, #E2E5EA);
  top: -20px;
}

.subscribed_item {
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  border-radius: 6px;
  border: 1px solid var(--gray-200---divider, #E2E5EA);
  background: var(--expert-primary-purple-10, #F9F8FE);
}

.info_tooltip {
  display: flex;
}

.tooltip_box {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;

  border-radius: 4px;
  border: 1px solid var(--gray-200---divider, #E2E5EA);
  background: var(--white, #FFF);

  align-self: flex-start;
}

.price_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.discount_price {
  color: var(--gray-500, #8B9099);
  /* body/small-400 */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  text-decoration-line: line-through;
}

.empty_text {
  color: var(--gray-700---text, #52545C);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.tooltip_box_2 {
  display: inline-flex;
  align-self: flex-start;
  gap: 4px;

  color: var(--expert-primary-purple-500, #866DEC);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: default;
}

.table:not(.payment_table) tr:first-child th:first-child::before, .table:not(.payment_table) td:first-child::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100% !important;
  background: var(--gray-200---divider, #E2E5EA);
  right: 0;
  top: 0;
}


.add_right_border::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100% !important;
  background: var(--gray-200---divider, #E2E5EA);
  right: 0;
  top: 0;
}

.table_left_border {
  border-left: 1px solid var(--gray-200---divider, #E2E5EA);
}

.table_point_cell {
  background: var(--expert-primary-purple-10, #F9F8FE) !important;
}

.cms_application_container {
  display: inline-flex;
  padding: 30px;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;

  border-radius: 12px;
  background: #F4F5F8;
}

.cms_application_form_container {
  display: flex;
  width: 271px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.cms_application_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.cms_application_form_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.is_agree_container {
  display: flex;
  width: 261px;
  justify-content: space-between;
  align-items: flex-start;
}

.is_agree_checkbox {
  display: flex;
  align-items: center;
  gap: 4px;

  color: var(--gray-900---heading, #1A1818);
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.cms_success_application_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  width: 611px;
  padding: 30px;

  border-radius: 12px;
  background: #F4F5F8;
}

.cms_success_application_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.cms_success_application_title strong {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 100% */
}

.cms_success_application_container p {
  padding: 24px;
  border-radius: 6px;
  background: var(--white, #FFF);

  color: var(--gray-700---text, #52545C);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.cms_success_application_container p strong{
  color: var(--gray-700---text, #52545C);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.vat_amt_text {
  color: var(--gray-500, #8B9099);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.point_red {
  color: var(--primary-red-500-main-color);
  font-weight: 500;
}