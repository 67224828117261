.toggle input {
  position: absolute; left: -10000px;
}

.toggle label {
  position: relative; 
  display: block;
  width: 40px; 
  height: 24px; 
  background: var(--gray-200-divider); 
  border-radius: 24px; 
  transition: all ease-out 0.25s;
  cursor: pointer;
}

.toggle input:checked + label {
  background: var(--expert-primary-purple-500);
}

.toggle label::after {
  position: absolute; 
  display: block; 
  content: "";
  width: 16px; 
  height: 16px; 
  left: 4px; 
  top: 4px; 
  background: #fff; 
  border-radius: 16px; 
  cursor: pointer;  
  transition: all ease-out 1s; 
  animation: leftMove 0.25s ease-in;
}

.toggle input:checked + label::after {
  left: 20px;
  animation: rightMove 0.25s ease-out;
}


.toggle input:disabled + label {
  background: #E7E2FB;
  pointer-events: none;
}


@keyframes rightMove { 
  0% { left: 4px; } 
  100% { left: 20px;}
}

@keyframes leftMove { 
  0% { left: 20px;} 
  100% { left: 4px;}
}
