:root {
  --white: #fff;
  --primary-color : #6B15EE;
  --primary-color-sub : #451195;
  --primary-blue-500-main-color : #407DF1;
  --primary-red-500-main-color : #FF4E4E;
  
  --gray-50-background : #f3f4f8;
  --gray-100 : #EDEEF2;
  --gray-200-divider : #E2E5EA; 
  --gray-300 : #C8CDD5;
  --gray-400-placeholder : #ACB1BA;
  --gray-500 : #8B9099;
  --gray-600 : #6D717B;
  --gray-700-text : #52545C;
  --gray-900-heading : #1A1818;
  
  --button-gray-hover : #4E4F57;
  --button-gray-press : #494A51;
  --button-gray-line-hover : #F9F9F9;
  --button-gray-line-press : #EDEDED;

  --body-small-400: 400 12px/12px "Pretendard", sans-serif;

  --expert-primary-gradient-900: linear-gradient(
    131.74deg,
    rgba(12, 24, 42, 1) 0%,
    rgba(33, 25, 48, 1) 100%
  );
  --expert-primary-gradient-800: linear-gradient(
    131.74deg,
    rgba(22, 48, 84, 1) 0%,
    rgba(67, 49, 95, 1) 100%
  );
  --expert-primary-gradient-700: linear-gradient(
    131.74deg,
    rgba(35, 73, 127, 1) 0%,
    rgba(100, 74, 142, 1) 100%
  );
  --expert-primary-gradient-600: linear-gradient(
    131.74deg,
    rgba(48, 97, 169, 1) 0%,
    rgba(132, 98, 190, 1) 100%
  );
  --expert-primary-gradient-500: linear-gradient(
    131.74deg,
    rgba(52, 120, 209, 1) 0%,
    rgba(166, 123, 237, 1) 100%
  );
  --expert-primary-gradient-400: linear-gradient(
    131.74deg,
    rgba(95, 148, 219, 1) 0%,
    rgba(184, 149, 241, 1) 100%
  );
  --expert-primary-gradient-300: linear-gradient(
    131.74deg,
    rgba(135, 175, 228, 1) 0%,
    rgba(202, 176, 244, 1) 100%
  );
  --expert-primary-gradient-200: linear-gradient(
    131.74deg,
    rgba(175, 201, 237, 1) 0%,
    rgba(220, 202, 248, 1) 100%
  );
  --expert-primary-gradient-100: linear-gradient(
    131.74deg,
    rgba(215, 228, 246, 1) 0%,
    rgba(237, 229, 251, 1) 100%
  );
  --expert-primary-gradient-50: linear-gradient(
    131.74deg,
    rgba(235, 242, 250, 1) 0%,
    rgba(246, 242, 253, 1) 100%
  );
  --expert-primary-gradient-10: linear-gradient(
    131.74deg,
    rgba(245, 248, 253, 1) 0%,
    rgba(251, 248, 254, 1) 100%
  );
  --expert-primary-purple-900: #1b162f;
  --expert-primary-purple-800: #362c5e;
  --expert-primary-purple-700: #50418e;
  --expert-primary-purple-600: #6b57bd;
  --expert-primary-purple-500: #866dec;
  --expert-primary-purple-400: #9e8af0;
  --expert-primary-purple-300: #b6a7f4;
  --expert-primary-purple-200: #cfc5f7;
  --expert-primary-purple-100: #e7e2fb;
  --expert-primary-purple-50: #f3f0fd;
  --expert-primary-purple-10: #f9f8fe;
  --expert-button-press: linear-gradient(
    131.74deg,
    rgba(37, 80, 140, 1) 0%,
    rgba(110, 83, 205, 1) 100%
  );
  --expert-button-hover: linear-gradient(
    131.74deg,
    rgba(48, 97, 169, 1) 0%,
    rgba(132, 98, 190, 1) 100%
  );
  --expert-button-defualt: linear-gradient(
    131.74deg,
    rgba(52, 120, 209, 1) 0%,
    rgba(166, 123, 237, 1) 100%
  );
  --expert-button-line-hover: #f6f3ff;
  --expert-button-line-press: #f0eaff;
  --secondary-blue-900: #11324b;
  --secondary-blue-800: #17496f;
  --secondary-blue-700: #1567a5;
  --secondary-blue-600: #248ad8;
  --secondary-blue-500-maincolor: #2ca4ff;
  --secondary-blue-400: #4cadff;
  --secondary-blue-300: #75c1ff;
  --secondary-blue-200: #a1d6ff;
  --secondary-blue-100: #b8e0ff;
  --secondary-blue-50: #cfeaff;
  --secondary-blue-10: #e7f5ff;
  --overlay-white-4: rgba(255, 255, 255, 0.04);
  --overlay-white-8: rgba(255, 255, 255, 0.08);
  --overlay-white-12: rgba(255, 255, 255, 0.12);
  --overlay-white-16: rgba(255, 255, 255, 0.16);
  --overlay-priamry-4: rgba(64, 125, 241, 0.04);
  --overlay-priamry-8: rgba(64, 125, 241, 0.08);
  --overlay-priamry-12: rgba(64, 125, 241, 0.12);
  --overlay-priamry-16: rgba(64, 125, 241, 0.16);
  --overlay-gray-4: rgba(26, 24, 24, 0.04);
  --overlay-gray-8: rgba(26, 24, 24, 0.08);
  --overlay-gray-12: rgba(26, 24, 24, 0.12);
  --overlay-gray-16: rgba(26, 24, 24, 0.16);
  --button-blue-hover: #3d75e0;
  --button-blue-press: #3a6dce;
  --button-blue-line-hover: #f0f5fe;
  --button-blue-line-press: #e0eafd;
  --button-gray-hover: #4e4f57;
  --button-gray-press: #494a51;
  --button-gray-line-hover: #f9f9f9;
  --button-gray-line-press: #ededed;


}


