.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container > div:first-child {
  margin-bottom: 24px;
}

.container h3 {
  color: #1A1818;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 100% */
}

.container :where(p, span) {
  color: #1A1818;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.container input {
  margin-top: 8px;
}

.link_container h3 {
  color: #1A1818;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
}

.link_container p {
  color: var(--gray-700-text, #52545C);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.link_container p b {
  font-weight: 600;
}

.linked_wrap_title {
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}

.linked_wrap {
  display: flex;
  height: 65px;
  width: 100%;
  padding: 10px;
  align-items: center;
  flex: 1 0 0;

  border-top: 1px solid var(--gray-200-divider, #E2E5EA);
  border-bottom: 1px solid var(--gray-200-divider, #E2E5EA);
}

.linked_wrap > div {
  padding: 10px;
}
.linked_wrap strong {
  width: 110px;
}