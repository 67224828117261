.collapse_custom {
    width: 100%;
}

.fee_container {
    display: flex;
    padding-block: 30px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    overflow-x: auto;
}


.answer {
    color: var(--gray-700---text, #52545C);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}


.example_text {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 20px;
}

.example_text span {
    line-height: 1.2;
}
