
.table{
    width: 100%;
    border: 1px solid var(--gray-200---divider, #E2E5EA);
}

.table thead td {
    border-right: 1px solid var(--gray-200---divider, #E2E5EA);
}

.table thead th:last-child{
    border-left: 1px solid var(--gray-200---divider, #E2E5EA);
}

.table thead :is(td, th){
    height: 72px !important;
    padding: 20px !important;
    border-bottom: none !important;
    background: var(--gray-50-background-divider, #F3F4F8) !important;

    color: var(--gray-900---heading, #1A1818) !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px; /* 100% */
}

.table tbody tr td{
    padding: 20px !important;
}


.table tbody tr td:not(:last-child){
    border-right: 1px solid var(--gray-200---divider, #E2E5EA);
}

.point_red_text {
    color: var(--primary-red-500---main-color, #FF4E4E);
}

.delete_text {
    color: var(--gray-600, #6D717B);
    text-decoration-line: line-through;
}

.price_title {
    color: var(--gray-900---heading, #1A1818);
    font-size: 14px;
    font-weight: 600;
    line-height: 14px; /* 100% */
}

.column_title {
    min-width: 90px;
}
@media (max-width:375px) {
    .column_title {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}