.container {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;

    border-radius: 6px;
    border: 1px solid var(--gray-200---divider, #E2E5EA);
    background: var(--white, #FFF);
}

.container img {
    width: 166px;
    height: 100%;
    object-fit: cover;
    flex-shrink: 0;

    border-radius: 6px;
    background: rgba(0, 0, 0, 0.50);
}

.onboarding_button {
    position: relative;
    height: 102px;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background: transparent;
}

.onboarding_button::after {
    position: absolute;
    content: "";
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    border-radius: 6px;
    background-image: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="36" height="36" rx="18" fill="white"/><path d="M26 18L14 24.9282L14 11.0718L26 18Z" fill="%2337383D"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
}

.description {
    display: flex;
    height: 102px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;

    flex: 1 0 0;
}

.description em {
    color: var(--gray-800, #37383D);

    /* body/medium-600 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
}

.description p {
    color: var(--gray-800, #37383D);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.tooltip_container {
    display: flex;
    padding: 10px 1px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 6px;
    background: var(--gray-700---text, #52545C);
}

.tooltip_container button {
    width: 100%;
    height: 130px;
    border-radius: 6px;
}

.tooltip_container img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.tooltip_container em{
    color: var(--white, #FFF);

    /* body/medium-600 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
}


.tooltip_container p{
    color: var(--gray-50-background-divider, #F3F4F8);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}