
/* Notification */
.notification_card {
  display: inline-flex;
  padding: 8px 16px;
  min-height: 65px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--gray-200-divider, #E2E5EA);
  background: var(--white, #FFF);
  box-sizing: border-box;
}

.notification_card[size=sm] {
  min-height: 36px;
}

.notification_card[type=info] {
  border-radius: 6px;
  border: 1px solid var(--primary-blue-100, #BFD3FA);
  background: var(--primary-blue-10, #E9F0FE);
}

.notification_card[type=success] {
  border: 1px solid var(--primary-green-100, #ACF0AB);
  background: var(--primary-green-10, #D9FBD8);
}

.notification_card[type=warning] {
  border: 1px solid var(--primary-yellow-200, #FFE79C);
  background: var(--primary-yellow-10, #FFF9E6);
}

.notification_card[type=error] {
  border: 1px solid var(--primary-red-200, #FFAFAF);
  background: var(--primary-red-10, #FFEAEA);
}


.message {
  color: var(--gray-700---text, #52545C);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}