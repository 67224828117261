
// SELECT
.ant-select {
  .ant-select-selector {
    height: 100%;
    padding: 0px 9px;
  }

  &:hover {
    .ant-select-selector {
      background-color: var(--gray-10);
    }
  }

  &.ant-select-multiple {
    height: 38px;

    .ant-select-selection-item {
      padding-inline-start: 0;
      align-items: center;
      background: none;

      .cnt {
        margin-left: 4px;
        font-weight: 600;
        color: var(--expert-primary-purple-500);
      }
    }
  }

  .ant-select-selection-placeholder svg {
    vertical-align: middle;
  }
}

.ant-select-dropdown {
  padding: 0;

  .ant-select-item {
    padding: 14px 12px 14px 12px;
    align-items: center;
    height: 38px;

    .ant-select-item-option-content {
      flex: 1;
      font-weight: 400;
    }

    &.ant-select-item-option-selected, &:hover {
      background-color: var(--gray-10) !important;
      color: var(--expert-primary-purple-500);

      .ant-select-item-option-content {
        font-weight: 500;
      }
    }

    &.ant-select-item-option-active {
      background-color: inherit;
    }
  }
}
.ant-select-item-multiple {
  .ant-select-item {
    padding: 14px 12px 14px 12px;
    align-items: center;
    height: 38px;

    .ant-select-item-option-content {
      margin-right: 34px;
    }


    &::after {
      position: absolute;
      content: url("../../assets/images/svg_icon/price-check-gray.svg");
      right: 12px
    }

    &.ant-select-item-option-selected {
      background-color: var(--gray-10);
      color: var(--expert-primary-purple-500);

      &::after {
        content: url("../../assets/images/svg_icon/price-check-green.svg");
      }
    }
  }
}
// END SELECT
