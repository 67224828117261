
.price_check_container {
    display: flex;
    width: 100%;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    border-radius: 6px;
    background: var(--gray-10, #F9FAFD);
}

.price_check_container div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.price_check_container span {
    color: var(--gray-700---text, #52545C);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px; /* 100% */
}

.price_check_container b {
    color: var(--gray-700---text, #52545C);
    font-size: 20px;
    font-weight: 700;
    line-height: 20px; /* 100% */
}


.button_wrap {
    column-gap: 0 !important;
}

.button_wrap button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.button_wrap button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.employee_count_wrap {
    display: flex;
    width: 310px;
    align-items: center;
    gap: 24px;

    color: var(--gray-900---heading, #1A1818);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.add_insurance_item_wrap {
    display: flex;
    width: 310px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;

    border-radius: 6px;
    background: #FFF;

    color: var(--gray-700---text, #52545C);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
}