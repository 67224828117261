
.container {
    height: "100%";
    position: relative;
    top: 112px;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 1280px) {
    .container {
        padding-top: 0px;
    }
}


.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;

    color: #1A1818;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px; /* 100% */
}

.method_list {
    display: flex;
    gap: 24px;
}

.method_list li {
    display: flex;
    width: 400px;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    border-radius: 12px;
    background: #F4F5F8;
}

.step {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: var(--expert-primary-purple-900, #1B162F);

    color: var(--white, #FFF);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px; /* 100% */
}

.method_step_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.method_step_title {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
}

.method_step_desc {
    color: var(--gray-700---text, #52545C);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
}

.noti_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.noti_container h4 {
    display: flex;
    align-items: center;
    gap: 8px;

    color: #1A1818;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px; /* 100% */
}

.noti_container ul {
    margin-left: 25px;
}

.noti_container ul li {
    list-style: disc;
    color: var(--gray-700---text, #52545C);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
}

.item_container {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    border-radius: 12px;
    background: var(--expert-primary-purple-50, #F3F0FD);
}

.item_container ol {
    display: flex;
    align-items: center;
    gap: 8px;
}

.item_container ol .item{
    display: flex;
    width: 148px;
    height: 140px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 12px;
    background: var(--expert-primary-purple-100, #E7E2FB);

    color: var(--gray-900---heading, #1A1818);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-align: center;
}


.title_name {
    color: var(--gray-900---heading, #1A1818);

    font-size: 14px;
    font-weight: 600;
    line-height: 14px; /* 100% */
}

.manger_container h3 {
    color: #1A1818;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px; /* 100% */
}



.wage_box {
    display: flex;
    width: 100%;
    padding: 30px 20px;
    margin: 24px 0;
    align-items: flex-start;
    gap: 20px;

    border-radius: 6px;
    border: 1px solid var(--gray-200---divider, #E2E5EA);
}

.wage_box_item {
    width: 408px;
    height: 45px;
    flex: 0 0 auto;
}

.box_title {
    color: var(--gray-700---text, #52545C);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
}

.box_content {
    color: var(--gray-900---heading, #1A1818);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
}

.employee_count_title {
    color: var(--gray-900---heading, #1A1818);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.count_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    padding: 10px;

    border-radius: 6px;
    background: var(--gray-50-background-divider, #F3F4F8);

    color: var(--gray-900---heading, #1A1818);
    font-size: 14px;
    font-weight: 600;
    line-height: 14px; /* 100% */
}

.employee_count_content {
    color: var(--gray-700---text, #52545C);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    word-break: keep-all;
}

.table_tr {
    display: flex;
    align-items: center;
}

.table_tr:not(:last-child) {
    border-bottom: 1px solid var(--gray-200---divider, #E2E5EA);
}

.table_td {
    width: 230px;
    padding: 10px;
    color: var(--gray-900---heading, #1A1818);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
}

.table_td:not(:last-child) {
    border-right: 1px solid var(--gray-200---divider, #E2E5EA);
}


@media (max-width: 992px) {
    .item_container ol .item{
        width: 120px;
        height: 120px;
    }
}


@media (max-width: 576px) {
    .price_check_container > div {
        flex-direction: column;
    }

    .list_wrap {
        flex-direction: column;
    }

    .list_wrap svg{
        transform: rotate(90deg);
    }

}

.wrapper {
    width: 100%;
}

.service_title {
    color: #1A1818;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;

    margin-bottom: 24px;
}


.point_text {
    color: var(--primary-blue-500---main-color, #407DF1);
}