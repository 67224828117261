.point_msg {
  color: var(--primary-blue-500---main-color, #407DF1);

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.modal_title {
  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--gray-900---heading, #1A1818);

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
}

.modal_title span {
  color: var(--expert-primary-purple-500, #866DEC);
}

.table_container {
  overflow: auto;
  width: 100%;
  height: 435px;
}

.table_container.on_bottom_modal {
  height: 250px;
}

.bottom_modal {
  position: absolute;
  display: flex;
  padding: 24px 40px;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  bottom: 60px;
  left: 0;
  right: 0;

  border-top: 1px solid var(--gray-200---divider, #E2E5EA);
  background: var(--white, #FFF);
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.10);
}

.bottom_modal_title {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;

  border-radius: 6px;
  background: var(--gray-50-background-divider, #F3F4F8);
}

.bottom_modal_title em {
  flex: 1 0 0;
  color: var(--gray-900---heading, #1A1818);

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
}

.bottom_modal_title span {
  color: var(--gray-900---heading, #1A1818);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.table_row {
  cursor: pointer;
}

.table_row:hover {
  background: var(--gray-50-background-divider, #F3F4F8);
}