.form {
  margin-top: 24px;
}

.form li { 
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
  width: 100%;
  border-top: 1px solid var(--gray-200-divider, #E2E5EA);
}

.form li:last-child {
  border-bottom: 1px solid var(--gray-200-divider, #E2E5EA);
}

.form li input {
  flex: 0 0 auto;
  box-sizing: border-box;
}