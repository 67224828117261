.item_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.item_title h4 {
    display: flex;
    gap: 4px;
    align-items: center;

    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
}


.item_box {
    width: 100%;
    padding: 30px 20px;
    border-radius: 6px;
    border: 1px solid var(--gray-200---divider, #E2E5EA);
}


.item_title > span {
    color: var(--gray-400---placeholder, #ACB1BA);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
}


.date_span {
    color: var(--gray-700---text, #52545C);

    font-size: 16px;
    font-weight: 600;
    line-height: 16px; /* 100% */
}

