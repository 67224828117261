
/* searchBox */
.search_box {
  position: relative;
  display: flex;
  align-items: center;
}

.search_box input {
  height: 38px;
  padding: 0px 40px 0px 12px;
  font-size: 14px;
  border-radius: 4px;
}

.search_box button {
  position: absolute;
  min-width: 38px;
  height: 38px;
  right: 0px;
  border: none;
  background: none;
  z-index: 1;
  padding: 0;
  cursor: pointer;
}

