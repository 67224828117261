.insurance_tab {
  padding-bottom: 200px;
}

.insurance_tab h3 {
  color: #1A1818;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.cell_title {
  color: var(--gray-700-text, #52545C);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
}

.cell_title_sub {
  color: var(--gray-500, #8B9099);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.insurance_table {
  display: flex;
  border-top: 1px solid var(--gray-200-divider, #E2E5EA);
  border-bottom: 1px solid var(--gray-200-divider, #E2E5EA);
}

.insurance_table_column_target {
  width: 231px;
  padding: 10px;
  border-right: 1px solid var(--gray-200---divider, #E2E5EA);
}

.insurance_table_column {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 10px;
}

.insurance_table_column li {
  display: inline-flex;
  align-items: center;
  gap: 70px;
}

.insurance_table_title {
  width: 155px;
}

.insurance_table_title span:last-child {
  color: var(--gray-500, #8B9099);
}

.insurance_table_column li div {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.insurance_table_column:last-child li p {
  flex: 0 0 auto;
  color: var(--gray-500, #8B9099);
}

.card {
  width: 300px;
  padding: 30px;
  background: var(--gray-50-background);
  border-radius: 10px;
}

.card h4 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.card h4 span {
  display: inline-block;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: #FFF;
  color: var( --expert-primary-purple-500);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 144.444% */
}

.card p {
  margin: 10px 0 20px 0;
  color: var(--gray-900-heading);
}

.exception_text {
  padding: 0;
  height: auto;
  color: var(--expert-primary-purple-500, #866DEC);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;

  text-decoration-line: underline;
  text-underline-position: from-font;
}

.exception_count {
  color: var(--gray-500, #8B9099);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.disabled_box {
  cursor: not-allowed;
}

.disabled_box_item {
  cursor: not-allowed;
}