.agree_check_list_wrap{ 
  height: 0;
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);

  &[data-event='on'] {
    height: auto;
    max-height: 360px;
    min-height: 160px;
    opacity: 1;
    transition: all 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);

    [data-icon] svg {
      background: red;
      transform: rotate(90deg);
    }
  }
}

.arrowIcon {
  transform: rotate(180deg);

  &[data-icon = 'on'] {
    transform: rotate(0deg);
  } 
  /* transition: all ease-out 0.5s; */
}