.hulam_button {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  height: 50px;
  border: none;
  border-radius: 6px;
  font-family: Pretendard;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; 
  cursor: pointer;
}

.hulam_button:disabled {
  color: var(--gray-300, #C8CDD5);
  border: 1px solid var(--gray-200---divider, #E2E5EA);
  background: var(--white, #FFF);
  pointer-events: none;
}

.primary {
  background: var(--expert-primary-gradient-500, linear-gradient(132deg, #3478D1 0%, #A67BED 95.93%));
  color: #FFF;
}

:is(.primary, .line-purple):hover{
  background: var(--expert-button-hover, linear-gradient(132deg, #3061A9 0%, #8462BE 95.93%));
  color: #fff !important;
}

:is(.primary, .line-purple):active {
  background: var(--expert-button-press, linear-gradient(132deg, #25508C 0%, #6E53CD 95.93%));
  color: #fff !important;
}

.line-purple {
  border: 1px solid var(--expert-primary-purple-500, #3478D1);
  background: var(--white, #FFF);
  color: var(--expert-primary-purple-500, #866DEC) !important;
}

.line-purple:hover {
  background: var(--expert-button-line-hover, #F6F3FF);
  color: var(--expert-primary-purple-500, #866DEC) !important;
}

.line-purple:active {
  background: var(--expert-button-line-press, #F0EAFF);
  color: var(--expert-primary-purple-500, #866DEC) !important;
}

.line-purple:disabled {
  border: 1px solid var(--gray-200---divider, #E2E5EA);
  background: var(--white, #FFF);
  color: var(--gray-300, #C8CDD5);
}

.background-purple {
  background: var(--expert-button-line-hover, #F6F3FF);
  color: var(--expert-primary-purple-500, #866DEC);
  font-size: 18px;
  line-height: 18px; /* 100% */
}


.text {
  background: none;
  border: none;
}

.text:disabled {
  border: none;
}

.secondary {
  background: var(--gray-700-text, #52545C);
  color: #FFF;
}

.secondary:hover {
  background: var(--button-gray-hover, #4E4F57);
}

.secondary:active {
  background: var(--button-gray-press);
}

:is(.primary:disabled, .secondary:disabled, .disabled) {
  background: var(--gray-100);
  color: var(--gray-300);
  pointer-events: none;
}

.line-purple {
  border: 1px solid var(--primary-color);
  background: #fff;
  color: var(--primary-color);
}

.line-gray {
  border: 1px solid var(--gray-200-divider);
  background: var(--white, #FFF);
  color: var(--gray-900-heading);
} 

.line-gray:hover {
  background: var(--button-gray-line-hover, #F9F9F9);
} 

.line-gray:active {
  background: var(--button-gray-line-press, #EDEDED);
} 


.large {
  height: 70px;
  border-radius: 12px;
}

@media (max-width: 600px) {
  .large {
    height: 50px;
    font-size: 16px;
    font-weight: 600;
  }
}



.sm {
  height: 38px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px; /* 100% */
}

.hulam_icon_button {
  display: flex;
  width: 38px;
  height: 38px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 6px;
  border: 1px solid var(--gray-200-divider, #E2E5EA);
  background: var(--white, #FFF);
  cursor: pointer;
}

.hulam_icon_button * {
  flex: 0 0 auto;
}

.hulam_icon_button.large {
  width: 50px;
  height: 50px;
}

.hulam_icon_button:hover {
  border: 1px solid var(--gray-200-divider, #E2E5EA);
  background: var(--button-gray-line-hover, #F9F9F9);
}

.hulam_icon_button:active {
  border: 1px solid var(--gray-200-divider, #E2E5EA);
  background: var(--button-gray-line-press, #EDEDED);
}

.hulam_icon_button:disabled {
  pointer-events: none;
}

