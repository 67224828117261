/****  FONT  ****/
/* Font - NotoSansKRKR */
@font-face { font-family: "notoSansKR"; font-style: normal; font-weight: 400; src: url("../fonts/NotoSansKR-Regular.eot"); src: url("../fonts/NotoSansKR-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Regular.woff") format("font-woff"), url("../fonts/NotoSansKR-Regular.woff2") format("woff2"), url("../fonts/NotoSansKR-Regular.otf") format("opentype"), url("../fonts/NotoSansKR-Regular.ttf") format("truetype"); }

@font-face { font-family: "notoSansKR"; font-style: normal; font-weight: 500; src: url("../fonts/NotoSansKR-Medium.eot"); src: url("../fonts/NotoSansKR-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Medium.woff") format("font-woff"), url("../fonts/NotoSansKR-Medium.woff2") format("woff2"), url("../fonts/NotoSansKR-Medium.otf") format("opentype"), url("../fonts/NotoSansKR-Medium.ttf") format("truetype"); }

@font-face { font-family: "notoSansKR"; font-style: normal; font-weight: 700; src: url("../fonts/NotoSansKR-Bold.eot"); src: url("../fonts/NotoSansKR-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Bold.woff") format("font-woff"), url("../fonts/NotoSansKR-Bold.woff2") format("woff2"), url("../fonts/NotoSansKR-Bold.otf") format("opentype"), url("../fonts/NotoSansKR-Bold.ttf") format("truetype"); unicode-range: U+AC00-D7A3; }

/* Font - Poppins */
@font-face { font-family: "Poppins"; font-style: normal; font-weight: 400; src: url("../fonts/Poppins-Regular.eot"); src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.otf") format("opentype"), url("../fonts/Poppins-Regular.ttf") format("truetype"); }

@font-face { font-family: "Poppins"; font-style: normal; font-weight: 500; src: url("../fonts/Poppins-SemiBold.eot"); src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.otf") format("opentype"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"); }

@font-face { font-family: "Poppins"; font-style: normal; font-weight: 700; src: url("../fonts/Poppins-Bold.eot"); src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/Poppins-Bold.otf") format("opentype"), url("../fonts/Poppins-Bold.ttf") format("truetype"); }


/* Font - Pretendard */
/* eot IE8 */
@font-face { font-family: "Pretendard"; font-style: normal; font-weight: 100; src: url("../fonts/pretendard/Pretendard-Thin.woff") format("woff"), url("../fonts/pretendard/Pretendard-Thin.woff2") format("woff2"), url("../fonts/pretendard/Pretendard-Thin.otf") format("opentype"), url("../fonts/pretendard/Pretendard-Thin.ttf") format("truetype"); }

@font-face { font-family: "Pretendard"; font-style: normal; font-weight: 200; src: url("../fonts/pretendard/Pretendard-ExtraLight.woff") format("woff"), url("../fonts/pretendard/Pretendard-ExtraLight.woff2") format("woff2"), url("../fonts/pretendard/Pretendard-ExtraLight.otf") format("opentype"), url("../fonts/pretendard/Pretendard-ExtraLight.ttf") format("truetype"); }

@font-face { font-family: "Pretendard"; font-style: normal; font-weight: 300; src: url("../fonts/pretendard/Pretendard-Light.woff") format("woff"), url("../fonts/pretendard/Pretendard-Light.woff2") format("woff2"), url("../fonts/pretendard/Pretendard-Light.otf") format("opentype"), url("../fonts/pretendard/Pretendard-Light.ttf") format("truetype"); }

@font-face { font-family: "Pretendard"; font-style: normal; font-weight: 400; src: url("../fonts/pretendard/Pretendard-Regular.woff") format("woff"), url("../fonts/pretendard/Pretendard-Regular.woff2") format("woff2"), url("../fonts/pretendard/Pretendard-Regular.otf") format("opentype"), url("../fonts/pretendard/Pretendard-Regular.ttf") format("truetype"); }

@font-face { font-family: "Pretendard"; font-style: normal; font-weight: 500; src: url("../fonts/pretendard/Pretendard-Medium.woff") format("woff"), url("../fonts/pretendard/Pretendard-Medium.woff2") format("woff2"), url("../fonts/pretendard/Pretendard-Medium.otf") format("opentype"), url("../fonts/pretendard/Pretendard-Medium.ttf") format("truetype"); }

@font-face { font-family: "Pretendard"; font-style: normal; font-weight: 600; src: url("../fonts/pretendard/Pretendard-SemiBold.woff") format("woff"), url("../fonts/pretendard/Pretendard-SemiBold.woff2") format("woff2"), url("../fonts/pretendard/Pretendard-SemiBold.otf") format("opentype"), url("../fonts/pretendard/Pretendard-SemiBold.ttf") format("truetype"); }

@font-face { font-family: "Pretendard"; font-style: normal; font-weight: 700; src: url("../fonts/pretendard/Pretendard-Bold.woff") format("woff"), url("../fonts/pretendard/Pretendard-Bold.woff2") format("woff2"), url("../fonts/pretendard/Pretendard-Bold.otf") format("opentype"), url("../fonts/pretendard/Pretendard-Bold.ttf") format("truetype"); }

@font-face { font-family: "Pretendard"; font-style: normal; font-weight: 800; src: url("../fonts/pretendard/Pretendard-ExtraBold.woff") format("woff"), url("../fonts/pretendard/Pretendard-ExtraBold.woff2") format("woff2"), url("../fonts/pretendard/Pretendard-ExtraBold.otf") format("opentype"), url("../fonts/pretendard/Pretendard-ExtraBold.ttf") format("truetype"); }

@font-face { font-family: "Pretendard"; font-style: normal; font-weight: 900; src: url("../fonts/pretendard/Pretendard-Black.woff") format("woff"), url("../fonts/pretendard/Pretendard-Black.woff2") format("woff2"), url("../fonts/pretendard/Pretendard-Black.otf") format("opentype"), url("../fonts/pretendard/Pretendard-Black.ttf") format("truetype"); }


body {
  margin: 0;
}

.section {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
}


