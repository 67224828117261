
/* 회원가입 */
.register { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); } 

@media (max-width: 767px){
 .register { top: 55%; } 
 }

.register.form { position: relative; top: 0; left: 0; transform: translate(0, 0); } 

.register .section_inner .section_top {
  margin-top: 112px;
  margin-bottom: 100px;  
}
.register .section_inner .section_tit { font-size: 32px;  background: linear-gradient(89.93deg, #6B15EE 0.03%, #039BE2 69.24%, #6B15EE 99.94%); -webkit-background-clip: text; background-clip: text; color: transparent; } 

@media (max-width: 1023px){
  .register .section_inner .section_top {
    margin-top: 0;
    margin-bottom: 40px;
  }
 .register .section_inner .section_tit {  font-size: 32px;} 
 }

@media (max-width: 767px){
 .register .section_inner .section_tit { width: 200px; margin: 0 auto; font-size: 2.2rem; } 
 }

.register .section_inner .section_img { display: block; margin: 0 auto 32px; } 

@media (max-width: 1023px){
 .register .section_inner .section_img { margin-bottom: 16px; } 
 }


.register .section_inner .section_info { position: relative; margin-top: 15px; text-align: center; } 

.register .section_inner .section_info .section_info_tit { font-size: 32px; font-weight: bold; line-height: 46px; } 

 @media (max-width: 1023px){
 .register .section_inner .section_info .section_info_tit { font-size: 2.3rem; line-height: 30px; } 
 }

@media (max-width: 767px){
 .register .section_inner .section_info .section_info_tit { font-size: 2rem; line-height: 20px; } 
 }

.register .section_inner .section_info .info_txt { margin: 20px 0; padding: 10px; text-align: center; letter-spacing: -1px; background: #FFF9D6; border: 1px solid #D6DAE1; border-radius: 6px; } 

.register .section_inner .register_container { display: flex; flex-direction: column; margin-top: 30px; justify-content: center; gap: 10px; } 

@media (max-width: 576px){
 .register .section_inner .register_container { flex-direction: column; } 
 }

.register .section_inner .register_container .register_box { display: flex; flex-direction: column; align-items: center; gap: 22px; } 

@media (max-width: 767px){
 .register .section_inner .register_container .register_box svg { width: 80px; height: 80px; } 
 }

.register .section_inner .register_container .register_box .btn_medium { cursor: pointer; } 

@media (max-width: 1023px){
 .register .section_inner .register_container .register_box .btn_medium { width: 260px; } 
 }
@media (max-width: 767px){
 .register .section_inner .register_container .register_box .btn_medium { width: 280px; font-size: 16px; height: 45px; line-height: 45px; } 
 }

@media (max-width: 576px){
 .register .section_inner .register_container .register_box .btn_medium { width: 100%; font-size: 12px; height: 40px; line-height: 40px; } 
 }

/* 회원가입 폼 */
.register.form { padding: 140px 0 100px; } 

@media (max-width: 576px) {
  .register.form { padding: 100px 0 55px; } 
}

.register.form .section_inner { max-width: 800px; } 

.register.form .section_inner.section_inner_form { max-width: 480px; } 

@media (max-width: 1300px) {
  .register.form .section_inner.section_inner_form { padding: 0; } 
}

@media (max-width: 576px) {
  .register.form .section_inner.section_inner_form { padding: 0 20px; } 
}

.register.form .section_inner .section_tit { background: #000; font-size: 32px; color: #000; -webkit-background-clip: text; background-clip: text; } 

@media (max-width: 1023px){
  .register.form .section_inner .section_tit { font-size: 3rem; } 
}

@media (max-width: 767px){
  .register.form .section_inner .section_tit { margin: 0; text-align: left; font-size: 2.2rem; } 
}

.register.form .section_inner .section_info { display: flex; flex-direction: column; text-align: left; gap: 23px; } 

.register.form .section_inner .section_info .sub_tit { margin-top: 60px; font-size: 22px; font-weight: 700; } 

@media (max-width: 576px) {
  .register.form .section_inner .section_info .sub_tit { margin-top: 40px; } 
}

.register.form .section_inner .section_info .form_info { display: flex; flex-direction: column; gap: 20px; margin-top: 22px; } 

.register.form .section_inner .section_info .form_info li { display: flex; align-items: center; flex-wrap: wrap; gap: 20px; } 

.register.form .section_inner .section_info .form_info li div{ position: relative; max-width: 334px; width: 100%;} 

.register.form .section_inner .section_info .form_info li div .frm_info{ display: block; padding-top: 5px;} 

@media (max-width: 576px) {
  .register.form .section_inner .section_info .form_info li div{ max-width: 100%;} 
}

.register.form .section_inner .section_info .form_info label { display: inline-block; width: 126px; font-size: 14px; font-weight: 700; } 

.register.form .section_inner .section_info .form_info input { display: block; width: 100%; height: 38px; padding-left: 10px; border: 1px solid #D6DAE1; border-radius: 4px; } 

.register.form .section_inner .section_info .form_info input.readonly { background: #ececec; } 

.register.form .section_inner .section_info .form_info .mb_zip_container { display: flex; } 

.register.form .section_inner .section_info .form_info input[name='mb_zip'] { width: 208px; } 

.register.form .section_inner .section_info .form_info input[name='mb_zip'] + button { width: 92px; height: 38px; margin-left: 10px; color: #fff; background: #555555; border-radius: 6px; cursor: pointer; } 

.register.form .section_inner .section_info .input_check_wrap label { font-size: 14px; font-weight: 400; line-height: 24px; } 

.register.form .section_inner .section_info .input_all_check + label { font-weight: 700; } 

.register.form .section_inner .section_info textarea { position: relative; top: -12px; padding: 12px; color: #7D7D89; border: 1px solid #9A9A9A; border-radius: 6px; resize: none; } 

.register.form .section_inner .section_info .agree_container { margin-top: 0px; } 

.register.form .section_inner .section_info .agree_container label, .register.form .section_inner .section_info .agree_container a { font-size: 14px; color: #1A1818; } 

.register.form .section_inner .section_info .agree_container label { padding-left: 35px; } 

.register.form .section_inner .section_info .agree_container a { text-decoration: underline; } 

.register.form .section_inner .section_info #daum_juso_pagemb_zip { position: absolute!important; z-index: 10; } 

.register.form .section_inner .btn_medium { width: 185px; height: 55px; margin: 0 auto; line-height: 55px; text-align: center; cursor: pointer; } 

.register.form .section_inner .btn_medium.btn_register { width: 100%; } 

/* 회원가입 결과 화면 */
.register.register_result .section_inner { max-width: 580px; } 

.register.register_result .section_inner .section_tit { background: linear-gradient(89.93deg, #6B15EE 0.03%, #039BE2 69.24%, #6B15EE 99.94%); -webkit-background-clip: text; background-clip: text; color: transparent; } 

@media (max-width: 1023px){
 .register.register_result .section_inner .section_tit { font-size: 3rem; } 
 }

.register.register_result .section_info { display: flex; flex-direction: column; gap: 20px; margin-top: 65px; font-size: 14px; font-weight: 400; letter-spacing: 0.06em; line-height: 21px; text-align: left; } 

.register.register_result .btn_area { display: flex; flex-wrap: wrap; gap: 15px; justify-content: center; } 

.register.register_result .btn_area .btn_custom { width: 260px; height: 70px; font-size: 18px; font-weight: 700; border-radius: 8px; line-height: 70px; } 

.register.register_result .btn_area .btn_custom.btn_primary { background: #6B15EE; color: #fff; } 

.register.register_result .btn_area .btn_custom.btn_border { border: 2px solid #6B15EE; color: #6B15EE; } 



.btn_regular { position: relative; display: inline-block; width: 150px; height: 48px; padding: 0 23px; font-size: 16px; font-weight: 500; color: #fff; text-align: center; line-height: 48px; border-radius: 8px; background: #6B15EE; letter-spacing: -0.56px; z-index: 1; } 

.btn_regular::after { position: absolute; content: ''; width: 0; height: 100%; top: 0; left: 0; border-radius: 8px; z-index: -1; background: rgba(0, 0, 0, 0.15); -webkit-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1); transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1); } 
 

.btn_regular:hover::after { left: auto; right: 0; width: 100%; } 

.btn_regular.wh_bg { color: #6B15EE; background: #fff; } 

.btn_regular.wh_bg::after { background: rgba(64, 125, 241, 0.15); } 

/* section */
.section .section_inner { position: relative;  margin: 0 auto; z-index: 9; } 

@media (max-width: 1300px){
 .section .section_inner { padding: 0 20px; } 
 }

.section .section_inner .section_tit { padding-bottom: 60px; font-size: 44px; font-weight: 700; line-height: 1.45; letter-spacing: -1.5px; } 

@media (max-width: 1023px){
 .section .section_inner .section_tit { padding-bottom: 24px; font-size: 2.2rem; } 
 }

.section .section_inner .section_tit.center { padding-bottom: 0; text-align: center; } 

.section .section_inner .section_tit #counter { font-family: "Poppins"; color: #6B15EE; letter-spacing: 5px; } 

@media (max-width: 1023px){
 .section .section_inner .section_tit #counter { letter-spacing: 1px; } 
 }

.section .section_inner .section_tit .sub_tit { margin-top: 16px; font-size: 22px; font-weight: 400; line-height: 1.45; letter-spacing: -0.77px; } 

@media (max-width: 1023px){
 .section .section_inner .section_tit .sub_tit { font-size: 1.6rem; margin-top: 8px; } 
 }

.section .section_inner h3 { margin-bottom: 23px; font-size: 32px; font-weight: 700; line-height: 1.5; letter-spacing: -1px; } 

@media (max-width: 1023px){
 .section .section_inner h3 { margin-bottom: 16px; font-size: 2rem; } 
 }

.section .section_inner h4 { margin: 16px 0 8px 0; font-size: 22px; font-weight: 700; line-height: 1.45; letter-spacing: -0.77px; } 

@media (max-width: 1023px){
 .section .section_inner h4 { font-size: 1.7rem; } 
 }

.section .section_inner .info_txt { 
  /* font-size: 16px; color: #555; line-height: 1.63; letter-spacing: -0.56px;  */
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} 

@media (max-width: 1023px){
 .section .section_inner .info_txt { font-size: 1.4rem; } 
 }

.section .swiper-container-horizontal > .swiper-pagination-bullets { bottom: 0; } 

.section .swiper-pagination-bullet { width: 12px; height: 12px; margin: 0 2px; background: #ddd; opacity: 1; } 

.section .swiper-pagination-bullet-active { width: 38px; height: 12px; background: #6B15EE; border-radius: 100px; } 

 
/* button */
.btn_area { margin-top: 60px; text-align: center; } 

@media (max-width: 1023px){
 .btn_area { margin-top: 24px; } 
 }

.btn_regular { position: relative; display: inline-block; width: 150px; height: 48px; padding: 0 23px; font-size: 16px; font-weight: 500; color: #fff; text-align: center; line-height: 48px; border-radius: 8px; background: #6B15EE; letter-spacing: -0.56px; z-index: 1; } 

.btn_regular::after { position: absolute; content: ''; width: 0; height: 100%; top: 0; left: 0; border-radius: 8px; z-index: -1; background: rgba(0, 0, 0, 0.15); -webkit-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1); transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1); } 
 
.btn_regular:hover::after { left: auto; right: 0; width: 100%; } 

.btn_regular.wh_bg { color: #6B15EE; background: #fff; } 

.btn_regular.wh_bg::after { background: rgba(64, 125, 241, 0.15); } 

.btn_medium { position: relative; display: inline-block; width: 260px; height: 56px; line-height: 56px; padding: 0; font-size: 18px; font-weight: 500; color: #fff; border-radius: 8px; background: #6B15EE; letter-spacing: -0.63px; overflow: hidden; z-index: 1; } 

@media (max-width: 1023px){
 .btn_medium { width: 100%; } 
 }

.btn_medium::after { position: absolute; content: ''; width: 0; height: 100%; top: 0; left: 0; z-index: -1; background: rgba(0, 0, 0, 0.15); -webkit-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);; transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1); } 

.btn_medium:hover::after { left: auto; right: 0; width: 100%; } 

.btn_medium.wh_bg { color: #6B15EE; background: #fff; border: 2px solid #6B15EE; } 

.btn_medium.wh_bg::after { background: rgba(64, 125, 241, 0.15); } 

/* .btn_medium.wh_bg:hover { color: #fff; } */

.btn_large { position: relative; display: inline-block; width: 260px; height: 70px; line-height: 70px; font-size: 18px; font-weight: 500; color: #fff; box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; border-radius: 8px; background: #6B15EE; text-align: center; letter-spacing: -0.63px; vertical-align: top; overflow: hidden; z-index: 10; cursor: pointer; } 

.btn_large::after { position: absolute; content: ''; width: 0; height: 100%; top: 0; left: 0; z-index: -1; background: rgba(0, 0, 0, 0.15); -webkit-transition: all 0.5s ease; transition: all 0.5s ease; } 

.btn_large:hover::after { left: auto; right: 0; width: 100%; } 

.btn_large.wh_bg { color: #6B15EE; background: #fff; } 

.btn_large.wh_bg::after { position: absolute; content: ''; width: 0; height: 100%; top: 0; left: 0; z-index: -1; background: rgba(64, 125, 241, 0.15); -webkit-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1); transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1); } 

.btn_large.wh_bg:hover::after { left: auto; right: 0; width: 100%; } 

.btn_detail { width: 124px; height: 50px; margin-left: 23px; padding: 14px 30px; font-size: 16px; font-weight: 500; line-height: 50px; color: #fff; background: #555; border-radius: 6px; } 

.bk_bg { color: #fff; background: #000; border: 2px solid #000; } 

.bk_bg::after { background: rgb(66, 66, 66); } 




.register .divider {
  position: relative;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  height: 18px;
  font-size: 0;
  line-height: 0;
}

.register .divider span {
  color: var(--gray-700-text);
  /* title/large-600 */
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 100% */
}

.register .divider::before {
  position: absolute;
  width: 25%;
  left: 0;
  height: 1px;
  margin: 8px 0;
  background-color: #D6DAE1;
  vertical-align: top;
  content: '';
  z-index: -1;
}

.register .divider::after {
  position: absolute;
  width: 25%;
  right: 0;
  height: 1px;
  margin: 8px 0;
  background-color: #D6DAE1;
  vertical-align: top;
  content: '';
  z-index: -1;
}



.login_title {
  background: linear-gradient(89.93deg, #6B15EE 0.03%, #039BE2 69.24%, #6B15EE 99.94%); 
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent;
  margin-bottom: 4px;
  text-align: center;
  font-Size: 44px;
  font-Weight: 700;
}