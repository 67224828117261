.find_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  margin: 0 auto;
}

.find_modal p {
  color: var(--gray-900-heading)
}

.find_modal_title_box {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-bottom: 40px;
}

.find_modal_title_box h2 {
  text-align: center;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 100% */
  background: var(--expert-primary-gradient-500, linear-gradient(132deg, #3478D1 0%, #A67BED 95.93%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
