.profile_container {
  width: 100%;
  padding-bottom: 80px;
}

.profile_container li { 
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
  width: 100%;
  border-top: 1px solid var(--gray-200-divider, #E2E5EA);
}

.profile_container li:last-child {
  border-bottom: 1px solid var(--gray-200-divider, #E2E5EA);
}

.profile_container li > div {
  width: 150px;
  flex: 0 0 auto;
}


.container h2{
  margin-top: 40px;
  margin-bottom: 30px;
  color: var(--gray-900-heading, #1A1818);
  font-size: 24px;
  font-weight: 700;
  line-height: 24px; /* 100% */
}