.hulam_checkbox {
  position: relative; 
  display: inline-flex;
  align-items: center;
  gap: 4px;

  color: var(--gray-900-heading);
  /* body/medium-400 ✅(pc) */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  cursor: pointer;
}

.hulam_checkbox input::after { display: block; content: ''; position: absolute; left: 0; top: 0; width: 19px; height: 19px; text-align: center; background: #fff; border-radius: 3px; border: 1px solid #d6dae1; background: #ffffff url("./img/unchecked.svg") center no-repeat; }

.hulam_checkbox input:checked::after { background: url("./img/check.svg") center no-repeat; color: #a8a9af; border: 1px solid transparent; }

.hulam_checkbox input[disabled]::after { background-image: url("./img/disabled-checked.svg"); background-position:  center;}

