.chips input {
    position: absolute;
    left: -99999px;
}

.chips label {
    display: inline-flex;
    height: 28px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 36px;
    border: 1px solid var(--gray-200---divider, #E2E5EA);
    background: var(--white, #FFF);

    color: var(--gray-900---heading, #1A1818);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    cursor: pointer;
}

.chips input:checked + label {
    background: var(--expert-primary-purple-500, #866DEC);
    color: var(--white, #FFF);
    border: none;
}

.chips input + label svg {
    flex: 1;
}

.chips input:checked + label svg {
    filter: brightness(0) invert(1);
}