.left_menu {
  display: flex;
  width: 256px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  background: var(--gray-100, #EDEEF2);
  flex: 0 0 auto;
}

.left_menu a {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  padding-left: 24px;

  color: #A8A9AF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.left_menu :where(a:hover, a.active) {
  background: var(--gray-400---placeholder, #ACB1BA);
  color: #FFF !important;
}

.ant-menu-item-active {
  background: var(--gray-400---placeholder, #ACB1BA);
  color: #FFF !important;
}

.left_menu :where(a:hover, a.active) svg {
  filter: brightness(0) invert(1);
}

.menu_item > li span {
  margin-left: 16px !important;
}

.menu_item ul > li {
  padding-left: 48px !important;
}