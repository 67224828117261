
.container {
  display: flex;
  padding: 30px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.item_container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;

  border-radius: 12px;
  background: var(--expert-primary-purple-50, #F3F0FD);
}

.item_container ol {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item_container ol .item{
  display: flex;
  flex-direction: column;
  width: 148px;
  height: 140px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 12px;
  background: var(--expert-primary-purple-100, #E7E2FB);

  color: var(--gray-900---heading, #1A1818);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: center;
}

.table_tr {
  display: flex;
  align-items: center;
}

.table_tr:not(:last-child) {
  border-bottom: 1px solid var(--gray-200---divider, #E2E5EA);
}

.table_td {
  padding: 10px;
  color: var(--gray-900---heading, #1A1818);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.table_td:not(:last-child) {
  border-right: 1px solid var(--gray-200---divider, #E2E5EA);
}

@media (max-width: 992px) {  
  .item_container ol .item{
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 576px) {  
  .list_wrap {
    flex-direction: column;
  }

  .list_wrap li > svg{
    transform: rotate(90deg);
  }

  .image_tooltip {
    display: none;
  }
}


.tooltip_title {
  color: var(--gray-900---heading, #1A1818);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.tooltip_text {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.tooltip_text_2 {
  color: var(--gray-700---text, #52545C);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}