.App {
  text-align: center;
  background: #F4F5F8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: var(--gray-900-heading);
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hulam_hompage_container {
  overflow: visible !important;

  & > div {
    overflow: visible !important;
  }
}
/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.authheader {
  max-height: 70px;
}

.shadow {
  border-bottom: 1.5px solid #E2E5EA;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
}

.hulam_container {
  display: flex;
  margin: 70px auto 0;
  width: 1600px;
  height: 100%;
  overflow: hidden;
  background: var(--white);
}

.hulam_container .inner {
  padding: 30px 40px;
  width: 100%;
}

.required {
  display: inline-block;
  padding-left: 4px;
  color: var(--primary-red-500-main-color, #FF4E4E);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

/* MESSAGE */
.ant-message {
  top: unset !important;
  bottom: 24px;
}

.ant-message .ant-message-notice .ant-message-notice-content {
  border-radius: 40px;
  background: #F4F5F8;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.ant-message .ant-message-notice .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: center;
  column-gap: 10px;
}


/* TABLE */
:is(.ant-table .ant-table-container .ant-table-content table) .ant-table-thead tr th,
.ant-table-tbody td {
  padding-left: 10px !important;
}

:is(.ant-table .ant-table-container .ant-table-content table .ant-table-tbody tr:hover) td {
  // background: none;
}

:is(.ant-table .ant-table-container .ant-table-content table .ant-table-thead) tr .ant-table-cell {
  height: 53px;
  background: white;
  border-start-end-radius: 0;
  border-start-start-radius: 0;
  border-top: 1px solid var(--gray-200-divider);
  border-bottom: 1px solid var(--expert-primary-purple-500);

  color: var(--gray-400-placeholder);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  vertical-align: middle;

  &::before {
    content: none !important;
  }

  &[scope=colgroup] {
    border-bottom: none;
  }
}

:is(.ant-table .ant-table-container .ant-table-content table .ant-table-tbody) tr .ant-table-cell {
  min-height: 65px;
  color: var(--gray-900-heading);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-table-thead > tr > th {
  vertical-align: middle !important;

  border-top: 1px solid var(--gray-200-divider);
}

.ant-table {
  .ant-table-tbody {
    .ant-table-cell {
      height: 65px;
      vertical-align: middle;
    }
  }
}


.hulampro_table {

  &.table_scroll {
    width: 100%;
    position: relative;

    thead {
      display: grid;
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;

      tr {
        display: flex;
        align-items: center;
      }

      border-bottom: 1px solid var(--primary-blue-500---main-color, #407DF1);
      border-top: 1px solid var(--gray-200---divider, #E2E5EA);
      :where(th, td) {
      }
    }

    tbody {
      display: grid;

      tr {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--gray-200---divider, #E2E5EA);

        :where(th, td) {
          align-items: center;
        }
      }

    }

    :where(th, td) {
      padding: 0 10px;
      vertical-align: middle;
      text-align: left;
      box-sizing: border-box;
    }
  }

  tr {
    height: 65px;
    vertical-align: middle;
  }
}


/* END TABLE */

// modal
.ant-modal-root {
  .ant-modal-content {
    padding: 0;

    .ant-modal-close {
      top: 13px;
      width: 38px;
      height: 38px;
    }

    .ant-modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 66px;
      padding: 13px 20px;
      margin: 0;
      background: var(--gray-50-background-divider, #F3F4F8);

      .ant-modal-title {
        /* title/large-700 ✅(pc) */
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 100% */
        color: var(--gray-900---heading, #1A1818);
      }
    }


    .ant-modal-body {
      padding: 40px;
      max-height: 70vh;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 14px 0;
      border-radius: 0px 0px 10px 10px;
      background: var(--gray-50-background);

      button {
        justify-content: center;
      }
    }
  }
}

//confirm
.ant-modal-confirm {
  padding: 0;
  padding-bottom: 0;

  .ant-modal-content {
    width: 416px;
    padding: 30px;
    border: 1px solid var(--gray-200-divider);
    border-radius: 6px;

    .ant-modal-body {
      padding: 0;

      .ant-modal-confirm-body {
        display: flex;
        align-items: center;
        justify-content: center;

        &.ant-modal-confirm-body-has-title>svg {
          display: none !important;
        }

        .ant-modal-confirm-title {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 5px;

          color: var(--gray-900-heading, #1A1818);
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          /* 100% */
        }

        .ant-modal-confirm-content {
          margin: 0;
          font-size: 14px;
          text-align: center;
          color: var(--gray-700-text);
          line-height: 20px;
          word-break: keep-all;
        }
      }

      .ant-modal-confirm-btns {
        display: flex;
        margin-top: 20px;
        justify-content: center;
        gap: 8px;
      }
    }
  }
}


.form {
  margin-top: 24px;
}

.form li {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
  width: 100%;
  border-top: 1px solid var(--gray-200-divider, #E2E5EA);
}

.form li:last-child {
  border-bottom: 1px solid var(--gray-200-divider, #E2E5EA);
}

.form li input {
  flex: 0 0 auto;
  box-sizing: border-box;
}

.error_message {
  color: var(--primary-red-500-main-color, #FF4E4E);
}

.file_name {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}


// tag
.ant-tag {
  display: inline-flex;
  padding: 8px 10px;
  margin-right: 0;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid var(--gray-200---divider, #E2E5EA);
  background: var(--white, #FFF);

  color: var(--gray-700---text, #52545C);
  font-family: Pretendard;
  font-size: 12px;
  height: 28px;

  &.ant-tag-blue {
    border: 1px solid var(--primary-blue-100, #BFD3FA);
    background: var(--primary-blue-10, #E9F0FE);
    color: var(--primary-blue-600, #285EC5);
  }

  &.ant-tag-green {
    border: 1px solid var(--primary-green-100, #ACF0AB);
    background: var(--primary-green-10, #D9FBD8);
    color: var(--primary-green-600, #10A54C);
  }

  &.ant-tag-error {
    border: 1px solid var(--primary-red-100, #FFC3C3);
    background: var(--primary-red-10, #FFEAEA);
    color: var(--primary-red-500---main-color, #FF4E4E);
  }

  &.ant-tag-gray {
    border: 1px solid var(--primary-red-100, #FFC3C3);
    color: var(--primary-red-500---main-color, #FF4E4E);
  }

  &.height-16 {
    height: 14px;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
  }
}

:is(.ant-tag-success) {
  border: 1px solid var(--primary-green-100, #ACF0AB);
  background: var(--primary-green-10, #D9FBD8);
  color: var(--primary-green-600, #10A54C);
}

//upload 
.ant-upload-wrapper {
  display: inline-flex;
  column-gap: 10px;


  .ant-upload-list-item {
    &:hover {
      background: inherit !important;
    }

    .ant-upload-icon {
      display: none;
    }

    .ant-upload-list-item-name {
      padding-left: 0 !important;
    }

    .ant-upload-list-item-action {
      opacity: 1 !important;
      vertical-align: middle;
    }
  }
}

.ant-menu {
  background: transparent;


  color: var(--gray-400---placeholder, #ACB1BA) !important;
  font-size: 14px;
  font-weight: 700;

  .ant-menu-title-content {
    color: var(--gray-400---placeholder, #ACB1BA);

    &:hover {
      color: white !important;
    }
  }

  :where(.ant-menu-item-selected, .ant-menu-item-active, .ant-menu-submenu-active) {
    svg {
      filter: brightness(0) invert(1);
    }

    .ant-menu-submenu-title {
      .ant-menu-title-content {
        color: white !important;
      }
    }
  }

  .ant-menu-item-selected.ant-menu-item-only-child {
    .ant-menu-title-content {
      color: white !important;
    }
  }

  .ant-menu-item-selected {
    .ant-menu-title-content {
      color: white !important;
    }
  }


  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      .ant-menu-title-content {
        color: #A8A9AF;
      }
    }

  }

  .ant-menu-submenu-arrow::before,  .ant-menu-submenu-arrow::after {
    background-color: rgba(172, 177, 186, 1);
  }
}

.ant-collapse {
  border-top: 1px solid var(--gray-200---divider, #E2E5EA);
  border-left: none;
  border-right: none;
  border-bottom: none;

  .ant-collapse-item {

    .ant-collapse-header {
      padding: 24px 20px;
      background: #fff;

      .ant-collapse-header-text {
        color: var(--gray-900---heading, #1A1818);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        /* 100% */
      }
    }

    .ant-collapse-content {
      border-top: none;

      .ant-collapse-content-box {
        padding: 20px;
      }
    }

    &:last-child {
      border-radius: 0;
    }

    &.ant-collapse-item-active {
      .ant-collapse-header-text {
        color: var(--expert-primary-purple-500);
      }
    }
  }
}

// 폼 아이템 에러 문구 하나만 띄워지도록 수정 나중에 에러날 가능성 있음 ..
.ant-form-item-explain > div:not(:nth-child(1)) {
  display: none;
}



// swiper bull
.swiper-pagination-bullet { width: 12px !important; height: 12px !important; margin: 0 2px; background: #ddd; opacity: 1; } 

.swiper-pagination-bullet-active{ width: 38px !important; height: 12px; background: #407df1; border-radius: 100px !important; } 
