.onboarding_wrap {
  .onboarding_title_box {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;

    h2 {
      display: flex;
      flex-direction: column;
      color: var(--gray-900---heading, #1A1818);
      font-size: 40px;
      font-weight: 500;
      gap: 28px;
    }



  }

  .onboarding_ord_box {
    padding-bottom: 200px;

    h3 {
      margin-bottom: 40px;
      color: var(--gray-900-heading, #1A1818);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    ol {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 40px;

      &::before {
        position: absolute;
        content: "";
        left: 20px;
        width: 1px;
        height: 410px;
        background: var(--gray-200-divider);
      }

      li {
        position: relative;
        display: flex;
        column-gap: 16px;

        * {
          flex: 0 0 auto;
        }



        .desc_no {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background: var(--primary-color);
          border-radius: 62.5px;

          color: var(--white, #FFF);
          font-size: 17.5px;
          font-style: normal;
          font-weight: 600;
          line-height: 17.5px;
          /* 100% */
        }

        .ord_title_box {
          margin-bottom: 24px;

          h4 {
            margin-bottom: 8px;
            color: var(--gray-900---heading, #1A1818);
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 90.909% */
          }

          p {
            color: var(--gray-700---text, #52545C);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
          }
        }
      }
    }


  }
}

.notice_wrap {
  width: 667px;
  margin-bottom: 30px;
}

.notice_top {
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: space-between;
}

.notice_top h2 {
  color: var(--gray-900---heading, #1A1818);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.notice_table_row {
  display: grid;
  height: 53px;
  align-items: center;
  grid-template-columns: 492px 175px;
  border-bottom: 1px solid #E2E5EA;
}

.notice_table_row:not(:first-child):hover {
  cursor: pointer;
  background: #F4F7FA;
}

.notice_table_row:first-child {
  border-top: 1px solid #1A1818;
}

.notice_table_row strong {
  color: var(--gray-900---heading, #1A1818);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 100% */
}

.notice_table_row :where(strong, span) {
  padding-left: 24px;
}

.notice_table_date {
  color: var(--gray-500, #8B9099);
}