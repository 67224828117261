/* Tooltip */
.ant-tooltip {
  max-width: none;

  .ant-tooltip-inner {
    padding: 6px 15px;
    min-width: 60px;
    color: #ededed;
    background: var(--gray-700-text);
    border-radius: 6px;
    z-index: 1;
    font-size: 14px;
    line-height: 20px;

    span {
      color: #ededed;
    }

    .empCnt {
      color: #A8A9AF;
    }
  }
}