
/* header */
header { 
  position: fixed;
  background: #fff; 
  width: 100%;
  height: 112px;
  z-index: 1000;
} 

.svg_icon_20_warning_blue{
  width:20px;
  height:20px;
  background: center/contain url(assets/images/blue.png) no-repeat; 

}

@media (max-width: 1280px) { header { height: 60px; line-height: 60px; background: #fff; } 
 } 

header .inner { 
  display: flex; 
  position: relative; 
  align-items: center; 
  justify-content: space-between; 
  width: 1280px; 
  max-width: 1600px; 
  height: 100%;
  margin: 0 auto; 
  padding: 15px 24px; 
} 


@media (max-width: 1300px) { 
  header .inner { width: 100%; padding: 0 20px; } 
}

@media (max-width: 1280px) {
  header .inner { height: 60px; } 
}

header .inner .logo { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-flex: 1; -ms-flex: 1; flex: 1; } 

header .inner .logo a { display: inline-flex; }

header nav { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; } 

@media (max-width: 1280px) {
  header nav { 
    display: none; 
    position: absolute; 
    top: 60px; 
    right: -110%; 
    width: 100%; 
    background: #fff; 
    -webkit-transition: all 0.5s; 
    transition: all 0.5s; 
  }
  header nav.on { 
    display: block; 
    overflow-y: auto; 
    height: calc(100vh - 60px); 
    right: 0; 
    z-index: 1000;
  } 
}

@media (max-width: 1280px) { header nav > ul { display: block !important; } 
 }

header nav .menu { 
  display: flex; 
  align-items: center; 
  justify-content: center; 
} 

header nav .menu.on > li:first-child { border-top: none; } 

header nav .menu > li { position: relative; height: 40px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; margin-right: 39px; } 

header nav .menu > li:hover:nth-child(1) > button::before { -webkit-transform: rotate(-180deg); transform: rotate(-180deg); -webkit-transition: all 0.3s; transition: all 0.3s; } 

header nav .menu > li:hover .sub_menu { display: block; } 

header nav .menu > li a img { vertical-align: baseline; } 

@media (max-width: 1280px) { header nav .menu > li { display: block; height: auto; margin-right: 0; border-top: 1px solid #e2e5ea; } 
 header nav .menu > li:last-child { border-bottom: 1px solid #e2e5ea; } 
 header nav .menu > li:hover:nth-child(1) > button::before { -webkit-transform: rotate(0deg); transform: rotate(0deg); } 
 header nav .menu > li:hover .sub_menu { display: none; } 
 }

header nav .menu > li:nth-child(1) > button { display: inline-block; position: relative; padding-right: 24px; } 

header nav .menu > li:nth-child(1) > button::before { content: ''; display: inline-block; position: absolute; width: 16px; height: 16px; right: 0; top: 9px; background: url("../../../assets/images/svg_icon/menu_arrow_down.svg") no-repeat center center; -webkit-transition: all 0.3s; transition: all 0.3s; }

header nav .menu > li.on:nth-child(1) > button::before { -webkit-transform: rotate(-180deg); transform: rotate(-180deg); -webkit-transition: all 0.3s; transition: all 0.3s; } 


@media (max-width: 1280px) { header nav .menu > li:nth-child(1) > button { display: block; } 
 }
 
@media (max-width: 1280px) { header nav .menu > li:nth-child(1) > button::before { right: 20px; top: 20px; } 
 }

header nav .menu > li.on .sub_menu { display: block; } 

header nav .menu > li .sub_menu { display: none; position: absolute; left: -24px; top: 40px; min-width: 180px; padding: 16px 0; border-radius: 8px; -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08); box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08); border: solid 1px #f4f7fa; background: #fff; -webkit-transition: all 0.3s; transition: all 0.3s; } 

@media (max-width: 1280px) { header nav .menu > li .sub_menu { position: relative; padding: 0 0 16px 0; left: 0; top: 0; border: none; -webkit-box-shadow: none; box-shadow: none; } 
 }

header nav .menu > li .sub_menu li a { display: block; padding: 0 24px; font-size: 16px; font-weight: 500; line-height: 36px; letter-spacing: -0.7px; } 

header nav .menu > li .sub_menu li a:hover { color: #6B15EE; background: #f4f7fa; } 

@media (max-width: 1280px) { header nav .menu > li .sub_menu li a { font-size: 14px; line-height: 40px; } 
 }

header nav :where(a, div) { color: #1A1818; font-size: 16px; font-style: normal; font-weight: 500; line-height: normal;} 

@media (max-width: 1280px) { header nav .menu li :where(button, a) { display: block; width: 100%; padding: 0 20px; line-height: 56px;  text-align: left;} 
 }

header nav .service_menu { 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex; 
  -webkit-box-align: center; 
  -ms-flex-align: center; 
  align-items: center; 
  -webkit-box-pack: center; 
  -ms-flex-pack: center; 
  justify-content: center; 
} 

@media (max-width: 1280px) { 
  header nav .service_menu { padding: 40px 20px 16px 20px; } 
  header nav .service_menu::before { content: none !important} 
}

header nav .service_menu > a { display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; position: relative; font-size: 16px; } 

header nav .service_menu::before { content: ''; display: inline-block; width: 1px; height: 30px;background: #ddd; } 

@media (max-width: 1280px) { header nav .service_menu span { width: calc(100%/2); margin-left: 8px; background: #6B15EE; border-radius: 8px; } 
 header nav .service_menu span .btn_regular { display: block; width: 100%; padding: 0 10px; height: 56px; line-height: 56px; color: #fff; text-align: center; } 
 }

header .m_menu { display: none; position: relative; right: -8px; width: 44px; height: 44px; padding: 8px; cursor: pointer; } 

@media (max-width: 1280px) { header .m_menu { display: block; } 
 }

header .m_menu span { display: inline-block; position: absolute; top: 12px; right: 8px; width: 20px; height: 2px; background: #1a1818; border-radius: 10px; -webkit-transition: all 0.3s; transition: all 0.3s; } 

header .m_menu span:nth-of-type(2) { top: 21px; right: 8px; width: 25px; } 

header .m_menu span:nth-of-type(3) { top: 31px; right: 8px; width: 15px; } 

header .m_menu.on span { -webkit-transition: all 0.3s; transition: all 0.3s; } 

header .m_menu.on span:nth-of-type(1) { width: 25px; -webkit-transform: rotate(45deg); transform: rotate(45deg); top: 21px; } 

header .m_menu.on span:nth-of-type(2) { display: none; } 

header .m_menu.on span:nth-of-type(3) { width: 25px; -webkit-transform: rotate(-45deg); transform: rotate(-45deg); top: 21px; } 

.header .h_left, .header .h_right { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; } 

.header .h_left .header_info { display: flex; padding: 7px 0 6px; gap: 15px } 

.header .logo { width: 216px; height: 38px; margin-right: 40px; } 

.header .logo a { display: inline-block; width: 100%; } 

.header .logo img { width: auto; height: 100%; max-width: 100%; } 

.header .header_info em { font-size: 18px; font-weight: 700; } 

.header .header_info .worker_num { font-size: 14px; } 

.header .util > * { display: inline-block; margin-left: 12px; vertical-align: middle; } 

.header .util .flex_box { display: flex; gap: 7px } 

.header .util .flex_box a { padding: 8px; border-radius: 6px; transition: all ease-out 0.25s; position: relative; } 

.header .util .flex_box a:hover { background: #F4F4F4; } 

.header .util_alert { position: relative; } 

.header .util_alert_dot { position: absolute; top: 2px; right: 0px; display: inline-block; width: 12px; height: 12px; border: 2px solid #fff; border-radius: 100%; background-color: #f46363; display: none; } 

.header .util_alert_dot.on { display: block; } 

.header .util_group { position: relative; } 

.header .util_group_count { position: relative; top: -6px; right: 12px; width: auto; height: 17px; padding: 1px 4px 2px 3px; border-radius: 100px; background-color: #407df1; text-align: center; color: #ffffff; font-size: 10px; font-weight: 600; line-height: 17px; } 

.header .util_user { max-height: 40px; margin-left: -1px; } 

.header .util_user_img { overflow: hidden; display: inline-block; width: 40px; height: 40px; border-radius: 100%; background-color: #f4f5f8; } 

.header .util_user_img img { max-width: 100%; height: auto; } 

.header .util_user_btn { margin-left: 4px; height: 40px; vertical-align: top; } 

.reg_button {
  display: inline-flex;
  justify-content: center;
  width: 110px;
  height: 48px;
  border-radius: 8px;
  background: #F4EDFE !important;
  color: #6B15EE;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blind { position: absolute; width: 1px; height: 1px; margin: -1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; text-indent: -9999px; } 

/* flex_box */
.flex_box { display: flex; gap: 7px; align-items: center; } 

.header .h_left .header_info { display: flex; padding: 7px 0 6px; gap: 15px } 

/* .bodyinner { display: flex; width: 1280px; margin: 0 auto; justify-content: space-between; }  */

#header.App-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  width: 100%;
  height: 70px;
  background: white;
  box-sizing: border-box;
  border-bottom: 1.5px solid #E2E5EA;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
}

#header .authheader {
  display: flex;
  width: 100%;
  max-width: 1600px;
  min-width: 1180px;
  height: 70px;
  padding: 15px 24px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

#header .authheader > * {
  flex: 0 0 auto;
}

#header .authheader .h_right {
  display: inline-flex;
}

#header .authheader .h_right svg {
  vertical-align: middle;
}
.info_box {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0 20px;
  height: 45px; 
  background: #FFF7E0;
  border: 1px solid #FAE5C6;
  border-radius: 6px;

}