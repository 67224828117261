.single_select_emp_box {
  .ant-modal-content {
    width: 380px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .ant-list {
    .ant-list-item {
      border-bottom: none;
      position: relative;
      transition: all ease-out 0.25s;
      cursor: pointer;

      &:hover {
        background: var(--gray-50-background);
      }
    }

    .ant-list-item-meta {
      align-items: center;
      margin-right: 10px;

      .ant-list-item-meta-avatar {
        display: flex;

        svg {
          flex: 0 0 auto
        }
      }

      .ant-list-item-meta-title {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 600;
        color: var(--gray-900-heading);
      }

      .ant-list-item-meta-description {
        font-size: 12px;
        font-weight: 400;
        color: var(--gray-400-placeholder);
      }
    }

    &.emp_list_item {
      .ant-list-item-action {
        position: absolute;
        margin: 0;
        left: 0;

        button {
          padding: 0;
          margin-left: 12px;
          margin-right: 2px;
          width: 32px;
          height: 32px;
          justify-content: center;
        }
      }
    }

    &.selected_emp_item {
      .ant-list-item-action {

        li {
          padding: 0;

          button {
            padding: 0;
            margin: 0;
            width: 38px;
            height: 38px;
            justify-content: center;
          }
        }
      }
    }
  }
}

.multi_select_emp_box {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  
  .ant-select-selection-selected-value {
    width: 500px;
  }

  .ant-modal-content {
    width: 760px;
    height: 669px;
    padding: 0;
    margin: 0;
    background: #fff;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }


  .ant-modal-footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .select_dept_box {
    .ant-select-selector {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .searchBox {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none !important;
  }

  .ant-list {
    .ant-list-item {
      border-bottom: none;
      position: relative;
      transition: all ease-out 0.25s;
      cursor: pointer;

      &:hover {
        background: var(--gray-50-background);
      }

      &:not(.selected_emp_item) {
        &::before {
          position: absolute;
          content: "";
          width: 24px;
          height: 24px;
          right: 30px;
          background: url(../../assets/images/svg_icon/price-check-gray.svg);
        }
      }

      &.active {
        background: var(--gray-50-background);

        &::before {
          background: url(../../assets/images/svg_icon/price-check-green.svg);
        }
      }

      .ant-list-item-meta {
        align-items: center;
        margin-right: 10px;

        .ant-list-item-meta-avatar {
          display: flex;

          svg {
            flex: 0 0 auto
          }
        }

        .ant-list-item-meta-title {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          color: var(--gray-900-heading);
        }

        .ant-list-item-meta-description {
          font-size: 12px;
          font-weight: 400;
          color: var(--gray-400-placeholder);
        }
      }

      &.emp_list_item {
        .ant-list-item-action {
          position: absolute;
          margin: 0;
          left: 0;

          button {
            padding: 0;
            margin-left: 12px;
            margin-right: 2px;
            width: 32px;
            height: 32px;
            justify-content: center;
          }
        }
      }

      &.selected_emp_item {
        .ant-list-item-action {

          li {
            padding: 0;

            button {
              padding: 0;
              margin: 0;
              width: 38px;
              height: 38px;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.ant-popover {
  .empDivider {
    position: relative;
    padding-left: 7px;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      width: 1px;
      height: 8px;
      background: var(--gray-200-divider);
      transform: translateY(-50%);
    }
  }

  .emp_join_group {
    font: var(--body-small-400);
    color: var(--gray-400-placeholder);

    b {
      font-weight: 600;
    }
  }

  .emp_item {
    font: var(--body-small-400);
    color: var(--gray-700-text);
    white-space: nowrap;

    * {
      flex: 0 0 auto
    }
  }
}